import axios from 'axios';

// const baseUrl = 'http://localhost:8080';
const baseUrl = 'https://api.upstrivesystem.com';

export async function sendTokenAsync(token) {
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/data_processing_agreement?token=${token}`,
	};
	return axios(options)
		.then((response) => 'ok')
		.catch((error) => 'ok');
}
