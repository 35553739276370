import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { sendTokenAsync } from './server';
import Check from './Check';
import CheckEmpty from './CheckEmpty';

function Home() {
	const [queryParameters] = useSearchParams();
	const [agreed, setAgreed] = useState(false);
	const [agreedFinished, setAgreedFinished] = useState(false);
	const token = queryParameters.get('token');
	const schoolName = queryParameters.get('schoolName');

	// useEffect(() => {
	// 	sendToken(token);
	// }, []);

	const sendToken = async () => {
		sendTokenAsync(token);
		setAgreedFinished(true);
	};

	return (
		<div className='mobile_only'>
			{/* style='margin: 0; display: flex; align-items: center; width: 100%; flex-direction: column' */}
			<div style={{ margin: 0, width: '100%', textAlign: 'center' }}>
				<div>
					<img src={require('./assets/images/upstrive_education_logo.png')} alt='Upstrive' style={{ width: 'min(200px, calc(100vw - 100px))', marginTop: 30 }} />
				</div>
				{!agreedFinished ? (
					<div style={{ backgroundColor: 'white', width: 'min(1100px, calc(100vw - 100px))', padding: 50, alignSelf: 'center', margin: 'auto', marginTop: 20, borderRadius: '50px 50px 0 0 ' }}>
						<div className='dpa-text'>
							<h2 style={{ color: '#42405f' }}>Data Processing Agreement</h2>
							<div style={{ margin: 'auto', alignSelf: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
								<p>between</p>

								<p style={{ minWidth: 500, borderBottom: '1px solid black', textAlign: 'center' }}>{schoolName ?? 'School Name'}</p>
								<p style={{ textAlign: 'center' }}>
									as Controller (hereinafter <b>“Controller“</b>),
								</p>
								<p>and</p>
								<p>
									<strong>Upstrive GmbH</strong>, Siezenheimer Straße 39, 5020 Salzburg, Austria
								</p>
								<p style={{ textAlign: 'right' }}>
									as Data Processor (hereinafter <b>„Data Processor“</b>, <br />
									Controller and Data Processor jointly the <b>“Parties”</b>)
								</p>
							</div>
							<p>&nbsp;</p>
							<p style={{ fontSize: 24, fontWeight: 'bold', textAlign: 'center' }}>Preamble</p>
							<p>
								The Controller has commissioned the Data Processor in a contract already concluded (hereinafter referred to as the <b>"Main Contract"</b>) for the services specified therein. Part of the execution of the contract is the processing of personal data. In particular, Art. 28 GDPR imposes specific requirements on such commissioned processing. To comply with these
								requirements, the Parties enter into the following Data Processing Agreement (hereinafter referred to as the <b>“Agreement”</b>), the performance of which shall not be remunerated separately unless expressly agreed.
							</p>

							<ol>
								<li>
									<strong> Definitions </strong>
								</li>
							</ol>
							<p>(1) Pursuant to Art. 4 (7) GDPR, the Controller is the entity that alone or jointly with other Controllers determines the purposes and means of the processing of personal data.</p>
							<p>(2) Pursuant to Art. 4 (8) GDPR, a Data Processor is a natural or legal person, authority, institution, or other body that processes personal data on behalf of the Controller.</p>
							<p>
								(3) Pursuant to Art. 4 (1) GDPR, personal data means any information relating to an identified or identifiable natural person (hereinafter <b>"Data Subject"</b>); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online
								identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.
							</p>
							<p>
								(4) Personal data requiring special protection are personal data pursuant to Art. 9 GDPR revealing racial or ethnic origin, political opinions, religious or philosophical beliefs or trade union membership of Data Subjects, personal data pursuant to Art. 10 GDPR on criminal convictions and criminal offenses or related security measures, as well as genetic data
								pursuant to Art. 4 (13) GDPR, biometric data pursuant to Art. 4 (14) GDPR, health data pursuant to Art. 4 (15) GDPR, and data on the sex life or sexual orientation of a natural person.
							</p>
							<p>
								(5) According to Article 4 (2) GDPR, the processing is any operation or set of operations that is performed upon personal data, whether or not by automatic means, such as collection, recording, organization, filing, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or
								combination, restriction, erasure or destruction.
							</p>
							<p>(6) Pursuant to Article 4 (21) GDPR, the supervisory authority is an independent state body established by a Member State pursuant to Article 51 GDPR.</p>
							<ol start='2'>
								<li>
									<strong style={{ textAlign: 'left' }}> Subject of the contract </strong>
								</li>
							</ol>
							<p>
								(1) The Data Processor provides the services specified in the Main Contract for the Controller. In doing so, the Data Processor obtains access to personal data, which the Data Processor processes for the Controller exclusively on behalf of and in accordance with the Controller's instructions. The scope and purpose of the data processing by the Data Processor are set
								out in the Main Contract and any associated service descriptions. The Controller shall be responsible for assessing the admissibility of the data processing.
							</p>
							<p>(2) The Parties conclude the present Agreement to specify the mutual rights and obligations under data protection law. In case of doubt, the provisions of this Agreement shall take precedence over the provisions of the Main Contract.</p>
							<p>(3) The provisions of this contract shall apply to all activities related to the Main Contract in which the Data Processor and its employees or persons authorized by the Data Processor come into contact with personal data originating from the Controller or collected for the Controller.</p>
							<p>(4) The term of this Agreement shall be governed by the term of the Main Contract unless the following provisions give rise to further obligations or termination rights.</p>

							<ol start='3'>
								<li>
									<strong> Right of instruction</strong>
								</li>
							</ol>
							<p>
								(1) The Data Processor may only collect, process or use data within the scope of the Main Contract and in accordance with the instructions of the Controller. If the Data Processor is required to carry out further processing by the law of the European Union or the Member States to which it is subject, it shall notify the Controller of these legal requirements prior
								to the processing.
							</p>
							<p>
								(2) The instructions of the Controller shall initially be determined by this Agreement. Thereafter, they may be amended, supplemented, or replaced by the Controller in writing or text form by individual instructions (Individual Instructions). The Controller shall be entitled to issue such instructions at any time. This includes instructions with regard to the
								correction, deletion, and blocking of data.
							</p>
							<p>(3) All instructions issued shall be documented by the Controller. Instructions that go beyond the service agreed in the Main Contract shall be treated as a request for a change in service.</p>
							<p>
								(4) If the Data Processor is of the opinion that an instruction of the Controller violates data protection provisions, it shall notify the Controller thereof without undue delay. The Data Processor shall be entitled to suspend the implementation of the relevant instruction until it is confirmed or amended by the Controller. The Data Processor may refuse to carry out
								an obviously unlawful instruction.
							</p>

							<ol start='4'>
								<li>
									<strong> Types of data processed, group of Data Subjects, third country </strong>
								</li>
							</ol>
							<p>
								(1) Within the scope of the implementation of the Main Contract, the Data Processor shall have access to the personal data specified in more detail in <b>Annex 1</b>.
							</p>
							<p>
								(2) The group of Data Subjects affected by the data processing is listed in <b>Annex 2</b>.
							</p>
							<p>(3) A transfer of personal data to a third country may only take place under the conditions of Art. 44 et seq. GDPR.</p>
							<ol start='5'>
								<li>
									<strong> Protective measures of the Data Processor </strong>
								</li>
							</ol>
							<p>(1) The Data Processor shall be obliged to observe the statutory provisions on data protection and not to disclose information obtained from the Controller's domain to third parties or expose it to their access. Documents and data shall be secured against disclosure to unauthorized persons, taking into account the state of the art.</p>

							<p>
								(2) The Data Processor shall organize the internal organization within its field of responsibility in such a way that it meets the special requirements of data protection. It shall have taken the technical and organizational measures specified in Annex 3 to adequately protect the Controller's data pursuant to Art. 32 GDPR, which the Controller acknowledges as
								adequate. The Data Processor reserves the right to change the security measures taken while ensuring that the contractually agreed level of protection is not undercut.
							</p>
							<p>
								(3) The persons employed in the data processing by the Data Processor are prohibited from collecting, processing or using personal data without authorization. The Data Processor shall oblige all persons entrusted by it with the processing and performance of this contract (hereinafter <b>"Employees"</b>) accordingly (obligation of confidentiality, Art. 28 (3) lit. b
								GDPR) and shall ensure compliance with this obligation with due care.
							</p>
							<p>
								(4) The Data Processor has appointed a data protection officer. The Data Processor’s data protection officer is heyData GmbH, Kantstr. 99, 10627 Berlin, <a href='mailto:datenschutz@heydata.eu'>datenschutz@heydata.eu</a>, <a href='www.heydata.eu'>www.heydata.eu</a>.
							</p>
							<ol start='6'>
								<li>
									<strong> Information obligations of the Data Processor </strong>
								</li>
							</ol>
							<p>
								(1) In the event of disruptions, suspected data protection violations or breaches of contractual obligations of the Data Processor, suspected security-related incidents or other irregularities in the processing of personal data by the Data Processor, by persons employed by it within the scope of the contract or by third parties, the Data Processor shall inform the
								Controller without undue delay. The same shall apply to audits of the Data Processor by the data protection supervisory authority. The notification of a personal data breach shall contain at least the following information:
							</p>
							<p style={{ paddingLeft: 30, margin: '10px 0' }}>(a) a description of the nature of the personal data breach, including, to the extent possible, the categories and the number of Data Subjects affected, the categories affected and the number of personal data records affected;</p>
							<p style={{ paddingLeft: 30, margin: '10px 0' }}>(b) a description of the measures taken or proposed by the Data Processor to address the breach and, where applicable, measures to mitigate its possible adverse effects;</p>
							<p style={{ paddingLeft: 30, margin: '10px 0' }}>(c) a description of the likely consequences of the personal data breach.</p>
							<p>(2) The Data Processor shall immediately take the necessary measures to secure the data and to mitigate any possible adverse consequences for the Data Subjects, inform the Controller thereof and request further instructions.</p>
							<p>(3) In addition, the Data Processor shall be obliged to provide the Controller with information at any time insofar as the Controller's data are affected by a breach pursuant to paragraph 1.</p>
							<p>(4) The Data Processor shall inform the Controller of any significant changes to the security measures pursuant to Section 5 (2).</p>

							<ol start='7'>
								<li>
									<strong> Control rights of the Controller </strong>
								</li>
							</ol>
							<p>
								(1) The Controller may satisfy itself of the technical and organizational measures of the Data Processor prior to the commencement of data processing and thereafter regularly on a yearly basis. For this purpose, the Controller may, for example, obtain information from the Data Processor, obtain existing certificates from experts, certifications or internal audits
								or, after timely coordination, personally inspect the technical and organizational measures of the Data Processor during normal business hours or have them inspected by a competent third party, provided that the third party is not in a competitive relationship with the Data Processor. The Controller shall carry out checks only to the extent necessary and shall not
								disproportionately disrupt the operations of the Data Processor in the process.
							</p>
							<p>(2) The Data Processor undertakes to provide the Controller, upon the latter's verbal or written request and within a reasonable period of time, with all information and evidence required to carry out a check of the technical and organizational measures of the Data Processor.</p>
							<p>
								(3) The Controller shall document the results of the inspection and notify the Data Processor thereof. In the event of errors or irregularities which the Controller discovers, in particular during the inspection of the results of the inspection, the Controller shall inform the Data Processor without undue delay. If facts are found during the control, the future
								avoidance of which requires changes to the ordered procedure, the Controller shall notify the Data Processor of the necessary procedural changes without delay.
							</p>
							<ol start='8'>
								<li>
									<strong> Use of service providers </strong>
								</li>
							</ol>
							<p>
								(1) The contractually agreed services shall be performed with the involvement of the service providers named in Annex 4 (hereinafter <b>“Sub-processors”</b>). The Controller grants the Data Processor its general authorization within the meaning of Article 28
							</p>
							<p>(2) s. 1 GDPR to engage additional Sub-processors within the scope of its contractual obligations or to replace Sub-processors already engaged.</p>
							<p>(4) When engaging Sub-processors, the Data Processor shall oblige them in accordance with the provisions of this Agreement. </p>
							<p>
								(5) A Sub-processor relationship within the meaning of these provisions does not exist if the Data Processor commissions third parties with services that are regarded as purely ancillary services. These include, for example, postal, transport and shipping services, cleaning services, telecommunications services without any specific reference to services provided by
								the Data Processor to the Controller and guarding services. Maintenance and testing services constitute Sub-processor relationships requiring consent insofar as they are provided for IT systems that are also used in connection with the provision of services for the Controller.
							</p>
							{/* <p></p> */}
							<ol start='9'>
								<li>
									<strong> Requests and rights of Data Subjects </strong>
								</li>
							</ol>
							<p>(1) The Data Processor shall support the Controller with suitable technical and organizational measures in fulfilling the Controller's obligations pursuant to Articles 12-22 and 32 to 36 GDPR.</p>
							<p>(2) If a Data Subject asserts rights, such as the right of access, correction or deletion with regard to his or her data, directly against the Data Processor, the latter shall not react independently but shall refer the Data Subject to the Controller and await the Controller's instructions.</p>
							<ol start='10'>
								<li>
									<strong> Liability </strong>
								</li>
							</ol>
							<p>(1) In the internal relationship with the Data Processor, the Controller alone shall be liable to the Data Subject for compensation for damage suffered by a Data Subject due to inadmissible or incorrect data processing under data protection laws or use within the scope of the commissioned processing.</p>
							<p>(2) The Data Processor shall have unlimited liability for damage insofar as the cause of the damage is based on an intentional or grossly negligent breach of duty by the Data Processor, its legal representative or vicarious agent.</p>
							<p>
								(3) The Data Processor shall only be liable for negligent conduct in the event of a breach of an obligation, the fulfillment of which is a prerequisite for the proper performance of the contract and the observance of which the Controller regularly relies on and may rely on, but limited to the average damage typical for the contract. In all other respects, the
								liability of the Processor - including for its vicarious agents - shall be excluded.
							</p>
							<p>(4) The limitation of liability pursuant to § 10.3 shall not apply to claims for damages arising from injury to life, body, health or from the assumption of a guarantee.</p>
							<ol start='11'>
								<li>
									<strong> Termination of the Main Contract </strong>
								</li>
							</ol>
							<p>
								(1) After termination of the Main Contract, the Data Processor shall return to the Controller all documents, data and data carriers provided to it or - at the request of the Controller, unless there is an obligation to store the personal data under Union law or the law of the Federal Republic of Germany - delete them. This shall also apply to any data backups at the
								Data Processor. The Data Processor shall on request provide documented proof of the proper deletion of any data.
							</p>
							<p>(2) The Controller shall have the right to control the complete and contractual return or deletion of the data at the Data Processor in an appropriate manner.</p>
							<p>
								(3) The Data Processor shall be obligated to keep confidential the data of which it has become aware in connection with the Main Contract even beyond the end of the Main Contract. The present Agreement shall remain valid beyond the end of the Main Contract as long as the Data Processor has personal data at its disposal which have been forwarded to it by the
								Controller or which it has collected for the Controller.
							</p>
							<ol start='12'>
								<li>
									<strong> Final provisions </strong>
								</li>
							</ol>
							<p>(1) To the extent that the Data Processor does not expressly perform support actions under this Agreement free of charge, it may charge the Controller a reasonable fee therefore, unless the Data Processor's own actions or omissions have made such support directly necessary.</p>
							<p>(2) Amendments and supplements to this Agreement must be made in writing. This shall also apply to any waiver of this formal requirement. The priority of individual contractual agreements shall remain unaffected.</p>
							<p>(3) If individual provisions of this Agreement are or become wholly or partially invalid or unenforceable, this shall not affect the validity of the remaining provisions.</p>
							<p>(4) This agreement is subject to German law.</p>
							<p>&nbsp;</p>
							{/* <p>&nbsp;</p> */}
							<h1>Annex</h1>
							<h3 style={{ fontSize: 22, fontWeight: 600 }}>Annex 1 - Description of the data/data categories</h3>
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<ul style={{ alignSelf: 'center' }}>
									<li>IP address</li>
									<li>name </li>
									<li>school position</li>
									<li>well-being data:</li>

									<ul>
										<li>emotions</li>
										<li>personal well-being state comparison </li>
									</ul>
								</ul>
							</div>
							<h3 style={{ fontSize: 22, fontWeight: 600 }}>Annex 2 - Description of affected Data Subject/groups of affected Data Subjects</h3>
							<p style={{ textAlign: 'center' }}>Controller, employees of the Controller, students </p>
							<p>&nbsp;</p>
							<h3 style={{ fontSize: 22, fontWeight: 600 }}>Annex 3 - Technical and organizational measures of the Data Processor</h3>
							<div>
								<ol start='1'>
									<li>
										<strong> Introduction </strong>
									</li>
								</ol>
								<p>This Annex summarizes the technical and organizational measures taken by the processor within the meaning of Article 32 (1) of the GDPR. These are measures with which the processor protects personal data. The purpose of the document is to support the processor in fulfilling its accountability obligations under Art. 5 (2) GDPR.</p>
								<ol start='2'>
									<li>
										<strong> Confidentiality (Art. 32 para. 1 lit. b DSGVO) </strong>
									</li>
								</ol>
								<p style={{ fontWeight: 600 }}>2.1 Entry control</p>
								<p>The following implemented measures prevent unauthorized persons from gaining access to the data processing facilities:</p>
								<ul>
									<li>Manual locking system (e.g. key)</li>
									<li>Work in the home office: unauthorized persons have no access to the employee's residence</li>
									<li>Work in the home office: instruction to employees, if possible, to work in workrooms separated from living quarters</li>
								</ul>
								<p style={{ fontWeight: 600 }}>2.2 Admission control</p>
								<p>The following implemented measures prevent unauthorized persons from accessing the data processing systems:</p>
								<ul>
									<li>Authentication with user and password</li>
									<li>Use of anti-virus software</li>
									<li>Use of firewalls</li>
									<li>Encryption of data carriers</li>
									<li>Use of 2-factor authentication</li>
									<li>General company policy on data protection or security</li>
								</ul>
								<p style={{ fontWeight: 600 }}>2.3 Access control</p>
								<p>The following implemented measures ensure that unauthorized persons do not have access to personal data:</p>
								<ul>
									<li>Number of administrators is kept as small as possible</li>
									<li>Management of user rights by system administrators</li>
								</ul>
								<p style={{ fontWeight: 600 }}>2.4 Separation control</p>
								<p>The following measures ensure that personal data collected for different purposes are processed separately:</p>
								<ul>
									<li>Separation of productive and test system</li>
									<li>Determination of database rights</li>
								</ul>
								<ol start='3'>
									<li>
										<strong> Integrity (Art. 32 para. 1 lit. b DSGVO) </strong>
									</li>
								</ol>
								<p style={{ fontWeight: 600 }}>3.1 Transfer control</p>
								<p>It is ensured that personal data cannot be read, copied, changed or removed without authorization during transfer or storage on data carriers and that it is possible to check which persons or bodies have received personal data. The following measures are implemented to ensure this:</p>
								<ul>
									<li>Email encryption</li>
									<li>Providing data over encrypted connections such as SFTP or HTTPS</li>
								</ul>
								<p style={{ fontWeight: 600 }}>3.2 Input control</p>
								<p>The following measures ensure that it is possible to check who has processed personal data in data processing systems and at what time:</p>
								<ul>
									<li>Clear responsibilities for deletions</li>
									<li>Logging of the entry, modification and deletion of data</li>
								</ul>
								<ol start='4'>
									<li>
										<strong> Availability and resilience (Art. 32 para. 1 lit. b DSGVO) </strong>
									</li>
								</ol>
								<p>The following measures ensure that personal data is protected against accidental destruction or loss and is always available to the client:</p>
								<ul>
									<li>Regular backups</li>
									<li>Creation of a backup & recovery concept</li>
									<li>Keeping data backups in a secure, off-site location</li>
									<li>Hosting (at least the most important data) with a professional hoster</li>
								</ul>
								<ol start='5'>
									<li>
										<strong> Procedures for regular review, assessment and evaluation (Art. 32(1)(d) GDPR; Art. 25(1) GDPR) </strong>
									</li>
								</ol>
								<p style={{ fontWeight: 600 }}>5.1 Data protection management</p>
								<p>The following measures are intended to ensure that an organization that meets the basic requirements of data protection law is in place:</p>
								<ul>
									<li>Using the heyData platform for data protection management</li>
									<li>Appointment of the data protection officer heyData</li>
									<li>Obligation of employees to data secrecy</li>
									<li>Regular training of employees in data protection</li>
									<li>Keeping an overview of processing activities (Art. 30 GDPR)</li>
								</ul>
								<p style={{ fontWeight: 600 }}>5.2 Incident response management</p>
								<p>The following measures are intended to ensure that notification processes are triggered in the event of data privacy breaches:</p>
								<ul>
									<li>Data breach notification process pursuant to Art. 4 No. 12 GDPR to the supervisory authorities (Art. 33 GDPR)</li>
									<li>Data breach notification process pursuant to Art. 4 No. 12 DSGVO vis-à-vis data subjects (Art. 34 DSGVO)</li>
									<li>Involvement of the data protection officer in security incidents and data breaches</li>
									<li>Use of anti-virus software</li>
								</ul>
								<p style={{ fontWeight: 600 }}>5.3 Data protection-friendly default settings (Art. 25 (2) GDPR)</p>
								<p>The following implemented measures take into account the requirements of the principles "Privacy by design" and "Privacy by default":</p>
								<ul>
									<li>Training of employees in "Privacy by design" and "Privacy by default".</li>
									<li>No more personal data is collected than is necessary for the respective purpose.</li>
								</ul>
								<p style={{ fontWeight: 600 }}>5.4 Order control</p>
								<p>The following measures ensure that personal data can only be processed in accordance with the instructions:</p>
								<ul>
									<li>Written instructions to the contractor or instructions in text form (e.g. by data processing agreement)</li>
									<li>Ensuring the destruction of data after completion of the order, e.g. by requesting appropriate confirmations</li>
									<li>Confirmation from contractors that they commit their own employees to data secrecy (typically in the data processing agreement)</li>
									<li>Careful selection of contractors (especially with regard to data security)</li>
									<li>Ongoing review of contractors and their activities</li>
								</ul>
								<h3 style={{ fontSize: 22, fontWeight: 600 }}>Annex 4 - Current Sub-processors</h3>
								<table border='1' style={{ borderColor: '#9392AD' }}>
									<tr>
										<th width='33%'>Name</th>
										<th width='33%'>Function</th>
										<th width='33%'>Server location</th>
									</tr>
									<tr>
										<td align='left'>MongoDB, Inc., 1633 Broadway, 38th Floor, New York, NY 10019, USA</td>
										<td>Database-as-a-Service</td>
										<td>EU</td>
									</tr>
									<tr>
										<td align='left'>Amazon Web Services EMEA Sàrl, Avenue John F. Kennedy 38, 1855 Luxemburg, Luxemburg</td>
										<td>Database-as-a-Service and cloud storage</td>
										<td>EU</td>
									</tr>
									<tr>
										<td align='left'>Functional Software, Inc., 132 Hawthorne Street San Francisco, CA 94107, USA</td>
										<td>Bug tracking in applications or on websites and monitoring of applications</td>
										<td>USA</td>
									</tr>
								</table>
							</div>
						</div>
						<div style={{ width: '100%', marginTop: 60, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 15 }}>
							<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
								<button className='agree-button' onClick={() => setAgreed(!agreed)}>
									{agreed ? <Check /> : <CheckEmpty />}
								</button>
								<p className='i-agree-text'>I agree with the DPA text above.</p>
							</div>
							<button className={`confirm-button ${agreed ? '' : 'disabled-button'}`} disabled={!agreed} onClick={sendToken}>
								Confirm
							</button>
						</div>
					</div>
				) : (
					<h3 style={{ color: 'white', textAlign: 'center', padding: '0 10px', maxWidth: '700px', margin: '20px auto' }}>
						Thank you for confirming the agreement to change the Data processing agreement.
						<br />
						<span
							style={{
								fontWeight: 400,
								fontSize: '14',
								lineHeight: '50px',
							}}
						>
							For more info check the new Data processing agreement{' '}
							<a href='https://upstrivesystem.com/cloud-services-agreement-schedule-b-data-processing-agreement/' style={{ color: '#FDDF6D' }}>
								here
							</a>
							.
						</span>
					</h3>
				)}
			</div>
		</div>
	);
}

export default Home;
