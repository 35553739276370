import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes, useSearchParams, Navigate } from 'react-router-dom';
import Home from './Home';

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<Home />} />
				<Route path='*' Component={() => <Navigate to='/' />}></Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
